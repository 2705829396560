interface Record {
    expireAt: number
    value: any
}

const keyPrefix = "cache"

export function setCache(key: string, value: any, expireInSeconds: number) {
    if (!window?.localStorage) return

    key = `${keyPrefix}:${key}`
    const record: Record = {
        expireAt: new Date().getTime() / 1000 + expireInSeconds,
        value: value
    }
    window.localStorage.setItem(key, JSON.stringify(record, (key, value) =>
        typeof value === 'bigint'
            ? value.toString()
            : value
    ));
}

export function getCache<T>(key: string): T | undefined {
    if (!window?.localStorage) return undefined

    key = `${keyPrefix}:${key}`
    const cachedData = JSON.parse(window.localStorage.getItem(key) || "{}")
    if (cachedData && cachedData.expireAt >= new Date().getTime() / 1000) return cachedData.value
    window.localStorage.removeItem(key)
    return undefined
}