import { PrivateKeyAccount, type Hex } from "viem";
import { generatePrivateKey, privateKeyToAccount, publicKeyToAddress } from "viem/accounts";
import Crypto from "../encrypt/EncryptionService";
import PasswordService from "../security/PasswordService";
import { WALLET_TYPE } from "./type";
import { BLOCKCHAIN_TYPE } from "../api/auth/type";
import {
  Contract,
  OpenedContract,
  TonClient,
  WalletContractV4,
  internal,
  Cell,
  beginCell,
  Address,
  external,
  storeMessage,
  toNano,
  address,
  fromNano,
} from "@ton/ton";
import { KeyPair, mnemonicNew, mnemonicToPrivateKey, sign } from "@ton/crypto";
import { Buffer } from "buffer";
import { postImportWallet } from "../api/wallet/api";
import { createSmartWallet, getUserInfo } from "../api/auth/api";
import { getAssets, NATIVE_TOKEN_RAW_ADDRESS } from "../tonkeeper";
import { getHttpEndpoint } from "@orbs-network/ton-access";

export function createTonWallet() {
  const account = ref<WalletContractV4 | null>(null);
  const auth = useAuthStore();
  const smart_data = computed<any>(() => ({}));
  const keypair = ref<KeyPair | null>(null);
  const contract = ref<OpenedContract<WalletContractV4> | null>(null);
  const client = ref<TonClient | null>(null);

  async function getTonClient() {
    if (client.value) return client.value;
    const endpoint = await getHttpEndpoint();
    const _client = new TonClient({
      endpoint: endpoint,
    });
    client.value = _client;
    return _client;
  }

  async function getNativeBalance() {
    if (!account.value) {
      account.value = await getSavedWallet();
    }
    if (!contract.value) throw new Error("Contract not found");
    const balance = await contract.value.getBalance();
    return Number(fromNano(balance));
  }

  async function getUserJettonWalletAddress(userAddress: string, jettonMasterAddress: string) {
    const _client = await getTonClient();
    const userAddressCell = beginCell().storeAddress(Address.parse(userAddress)).endCell();

    const response = await _client.runMethod(Address.parse(jettonMasterAddress), "get_wallet_address", [{ type: "slice", cell: userAddressCell }]);

    return response.stack.readAddress();
  }

  async function sendTokenTransaction(params: { to: string; value: string; contract_address: string }) {
    if (!account.value) return "";
    if (!contract.value) return "";
    if (!keypair.value) return "";
    const { init } = contract.value;
    try {
      const _client = await getTonClient();
      const contractDeployed = await _client.isContractDeployed(Address.parse(account.value.address.toString()));
      let neededInit: null | typeof init = null;

      if (init && !contractDeployed) {
        neededInit = init;
      }

      const jettonWalletAddress = await getUserJettonWalletAddress(account.value?.address.toString() || "", params.contract_address);
      const messageBody = beginCell()
        .storeUint(0x0f8a7ea5, 32) // opcode for jetton transfer
        .storeUint(0, 64) // query id
        .storeCoins(toNano(params.value)) // jetton amount, amount * 10^9
        .storeAddress(address(params.to))
        .storeAddress(account.value.address)
        .storeBit(0) // no custom payload
        .storeCoins(0) // forward amount - if > 0, will send notification message
        .storeBit(0) // we store forwardPayload as a reference, set 1 and uncomment next line for have a comment
        // .storeRef(forwardPayload)
        .endCell();

      const internalMessage = internal({
        to: jettonWalletAddress,
        value: toNano("0.02"),
        bounce: false,
        body: messageBody,
      });

      const seqno = await contract.value.getSeqno();

      const body = account.value.createTransfer({
        seqno,
        secretKey: keypair.value.secretKey,
        messages: [internalMessage],
      });

      const externalMessage = external({
        to: account.value.address.toString(),
        init: neededInit as any,
        body,
      });
      const externalMessageCell = beginCell().store(storeMessage(externalMessage)).endCell();

      const signedTransaction = externalMessageCell.toBoc();
      const hash = externalMessageCell.hash().toString("hex");

      console.log("hash:", hash);

      await _client.sendFile(signedTransaction);
      return hash;
    } catch (error) {
      console.log("ton send token error", error);
      return "";
    }
  }

  async function sendTransaction(params: { to: string; value: string; contract_address: string }) {
    console.log("ton send transaction", params);
    if (params.contract_address !== NATIVE_TOKEN_RAW_ADDRESS) return sendTokenTransaction(params);
    if (!account.value) {
      account.value = await getSavedWallet();
    }
    if (!keypair.value) throw new Error("KeyPair not found");
    if (!account.value) throw new Error("Account not found");
    if (!contract.value) throw new Error("Contract not found");

    console.log("start");

    try {
      const seqno = await contract.value.getSeqno();

      const body = account.value.createTransfer({
        seqno,
        secretKey: keypair.value.secretKey,
        messages: [
          internal({
            ...params,

            body: "",
          }),
        ],
      });

      const _client = await getTonClient();
      const hash = body.hash().toString("hex");
      console.log("hash", hash);

      await _client.sendExternalMessage(account.value as WalletContractV4, body);
      console.log("send done");
      return hash;
    } catch (error) {
      console.log("ton send error", error);
      return "";
    }
  }

  async function signMessage(message: string, option?: { hash?: boolean }) {
    if (!account.value) {
      account.value = await getSavedWallet();
    }

    if (!keypair.value) throw new Error("KeyPair not found");

    if (!account.value) throw new Error("Account not found");
    if (!contract.value) throw new Error("Contract not found");

    const dataHash = Buffer.from(message);

    const signature = sign(dataHash, keypair.value?.secretKey);

    return signature as any;
  }
  async function signTypedData(payload: any, provider = "EVM") {
    if (!account.value) {
      account.value = await getSavedWallet();
    }
    return "0x" as any;
  }

  async function getSavedWallet(): Promise<any> {
    const ton_saved_enc = localStorage.getItem("TON_WALLET_SAVED");
    if (ton_saved_enc) {
      const mneStr = Crypto.decrypt(ton_saved_enc, PasswordService.getPassword());
      const mnemonics = mneStr.split("-");
      const _keyPair = await mnemonicToPrivateKey(mnemonics);

      keypair.value = _keyPair;

      const wallet = WalletContractV4.create({ workchain: 0, publicKey: _keyPair.publicKey });

      account.value = wallet;
      console.log("ton wallet", wallet.address.toString());

      const _client = await getTonClient();
      const _contract = _client.open(wallet);

      contract.value = _contract;
      _contract.getBalance();

      return wallet;
    }
    return generateWallet();
  }

  async function generateWallet(mnemonic_imported?: string) {
    // Generate new key
    if (mnemonic_imported && mnemonic_imported.split(" ").length !== 24) {
      throw new Error("Invalid Mnemonic");
    }
    const mnemonics = mnemonic_imported?.split(" ") || (await mnemonicNew());

    const mneStr = mnemonics.join("-");
    const enc = Crypto.encrypt(mneStr, PasswordService.getPassword());

    localStorage.setItem("TON_WALLET_SAVED", enc);
    const _keyPair = await mnemonicToPrivateKey(mnemonics);
    keypair.value = _keyPair;

    const workchain = 0; // Usually you need a workchain 0
    const wallet = WalletContractV4.create({ workchain, publicKey: _keyPair.publicKey });
    const address = wallet.address.toString();
    console.log("generate ton wallet", address);
    const userWallet = await postImportWallet(address, "Ton Wallet");
    // const res = await createSmartWallet(enc, wallet.address.toString(), "", "ton", "");
    if (userWallet) {
      await getUserInfo();
    }

    wallet.createTransfer({
      async signer(message: Cell): Promise<Buffer> {
        return new Buffer("");
      },
      messages: [
        internal({
          value: "0.0001",
          to: wallet.address.toString(),
        }),
      ],
      seqno: 0,
    });
    return wallet;
  }

  async function clear(id: string) {
    account.value = null;
  }

  return {
    address: computed(() => {
      return account.value?.address?.toString() || "";
    }),
    get smart_data() {
      return smart_data.value;
    },
    get smart_address() {
      return account.value?.address?.toString() || "";
    },
    wallet_type: "ton_normal" as WALLET_TYPE,
    blockchain: "ton" as BLOCKCHAIN_TYPE,
    name: auth.getUser().user_wallets?.find((w) => w.address === account.value?.address.toString())?.name || "Ton Wallet",
    smart_name: "EVM Smart Wallet",
    is_smart: true,
    signMessage,
    generateWallet,
    signTypedData,
    getSavedWallet,
    clear,
    tgCloudBackuped: true,
    sendTransaction,
    getNativeBalance,
  };
}
