import { createPublicClient, erc20Abi, formatEther, getContract, formatUnits, http, type Chain } from "viem";
import * as wChains from "viem/chains";
import { TON_CHAIN_ID } from "~/services/tonkeeper";

const chainByID: Record<number, Chain> = Object.values(wChains).reduce((res, c) => {
  res[c.id] = c;
  return res;
}, {} as Record<number, Chain>);

export function getClient(chainId: number) {
  const chain = chainByID[chainId];
  if (!chain) {
    return undefined;
  }
  return createPublicClient({
    chain: chain,
    transport: http(undefined, {
      batch: true,
    }),
  });
}

export async function getTokenInfo({ chainId, contractAddress }: { chainId: number; contractAddress: string }) {
  try {
    const publicClient = getClient(chainId);
    if (!publicClient) {
      console.log("chain not found", chainId);
      return null;
    }
    const contract = getContract({
      address: contractAddress as any,
      abi: erc20Abi,
      client: {
        public: publicClient,
      },
    });
    return {
      name: await contract.read.name(),
      symbol: await contract.read.symbol(),
      decimals: await contract.read.decimals(),
    };
  } catch (error) {
    return null;
  }
}

export async function getBalanceOfToken(params: {
  contractAddress: string;
  userAddress: string;
  chainID: number;
  decimal: number;
  standard?: string;
}) {
  try {
    const publicClient = getClient(params.chainID);
    if (!publicClient) {
      console.log("chain not found", params.chainID);
      return "0";
    }

    if (params.contractAddress.startsWith("0xEeee") || params.standard === "native") {
      const balanceUnit = await publicClient.getBalance({
        address: params.userAddress as any,
      });
      const balanceEther = formatEther(balanceUnit);
      return balanceEther;
    }
    const contract = getContract({
      address: params.contractAddress as any,
      abi: erc20Abi,
      client: {
        public: publicClient,
      },
    });

    const balance = await contract.read.balanceOf([params.userAddress as any]);
    return formatUnits(balance, params.decimal);
  } catch (error) {
    return "0";
  }
}

export async function estimateGas(
  params: { data: `0x${string}`; from: `0x${string}`; to: `0x${string}` },
  connectedInfor: { chainId: number; address: string }
) {
  const publicClient = getClient(connectedInfor.chainId);
  if (!publicClient) {
    throw new Error("not supported chain");
  }
  return await publicClient.estimateGas({
    account: connectedInfor.address as `0x${string}`,
    to: params.to,
    data: params.data,
  });
}

export async function getTokenAllowance({
  chainId,
  contractAddress,
  walletAddress,
  decimal,
  tokenAddress,
}: {
  chainId: number;
  contractAddress: string;
  walletAddress: string;
  decimal: number;
  tokenAddress: string;
}) {
  try {
    const publicClient = getClient(chainId);
    if (!publicClient) {
      console.log("chain not found", chainId);
      return 0;
    }
    const contract = getContract({
      address: tokenAddress as any,
      abi: erc20Abi,
      client: {
        public: publicClient,
      },
    });
    const allowance = await contract.read.allowance([walletAddress as any, contractAddress as any]);
    console.log("allowance", allowance);
    return Number(formatUnits(allowance, decimal));
  } catch (error) {
    return 0;
  }
}

export function getTransactionExplorer(chainId: number, hash: string) {
  if (Number(chainId) === TON_CHAIN_ID) {
    return `https://tonscan.org/tx/${hash}`;
  }
  const chain = chainByID[chainId];
  if (!chain) {
    return undefined;
  }
  return `${chain.blockExplorers?.default.url}/tx/${hash}`;
}

export function getContractExplorer(chainId: number, address: string) {
  if (Number(chainId) === TON_CHAIN_ID) {
    return `https://tonscan.org/address/${address}`;
  }
  const chain = chainByID[Number(chainId)];
  if (!chain) {
    return undefined;
  }
  return `${chain.blockExplorers?.default.url}/address/${address}`;
}
